const Ranking = {
    name: "실시간 TOP3 랭킹",
};

const Announcement = {
    name: "공지사항",
};

const banner = {
    url: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Banner.png",
};

export { Ranking, Announcement, banner };
