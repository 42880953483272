// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* contents.css */
.contents {
    margin-top: 20px;
    overflow: auto;
    width: 100%;
    height: 75%;
    /* 스크롤바 스타일 설정 */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.contents::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}
`, "",{"version":3,"sources":["webpack://./src/CSS/Contents.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,wBAAwB,EAAE,gBAAgB;IAC1C,qBAAqB,EAAE,YAAY;AACvC;;AAEA;IACI,aAAa,EAAE,0BAA0B;AAC7C","sourcesContent":["/* contents.css */\r\n.contents {\r\n    margin-top: 20px;\r\n    overflow: auto;\r\n    width: 100%;\r\n    height: 75%;\r\n    /* 스크롤바 스타일 설정 */\r\n    -ms-overflow-style: none; /* IE and Edge */\r\n    scrollbar-width: none; /* Firefox */\r\n}\r\n\r\n.contents::-webkit-scrollbar {\r\n    display: none; /* Chrome, Safari, Opera */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
