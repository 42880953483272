const chargeTerms = {
    Term_1: {
        title: "제1조(목적)",
        content: [
            "이 약관은 데스프온라인 회사(전자상거래 사업자)가 운영하는 서버(이하 “서버”라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 서버와 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.",
            "※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」",
        ],
    },
    Term_2: {
        title: "제2조(정의)",
        content: [
            "① “서버”란 데스프온라인 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버서버를 운영하는 사업자의 의미로도 사용합니다.",
            "② “이용자”란 “서버”에 접속하여 이 약관에 따라 “서버”가 제공하는 서비스를 받는 사용자를 말합니다.",
            "③ “캐시”란 “이용자”가 회사로부터 구매한 “포인트”를 의미합니다.",
        ],
    },
    Term_3: {
        title: "제3조 (약관 등의 명시와 설명 및 개정)",
        content: [
            "① “서버”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 00 사이버서버의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.",
            "② “서버”는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.",
            "③ “서버”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.",
            "④ “서버”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서버의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “서버”은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.",
            "⑤ “서버”가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “서버”에 송신하여 “서버”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.",
            "⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.",
        ],
    },
    Term_4: {
        title: "제4조(서비스의 중단)",
        content: [
            "① “서버”는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.",
            "② “서버”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “서버”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.",
            "③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “서버”는 제8조에 정한 방법으로 이용자에게 통지하고 당초 “서버”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “서버”가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 캐시를 “서버”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.",
        ],
    },
    Term_5: {
        title: "제5조(환급)",
        content: [
            "“서버”는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3 영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.",
        ],
    },
    Term_6: {
        title: "제6조(청약철회 등)",
        content: [
            "① “서버”의 상품을 구매한 사용자는 아래의 경우에 한해서 결제에 대한 취소 및 환불을 요구할 수 있습니다.",
            "② - “이용자”는 사용하지 않은 “캐시”에 한해 충전 완료일로부터 7일 이내에 환불을 요청할 수 있습니다.",
            "③ - “서버”의 과실로 인해, “캐시”의 충전이 완료되지 않은 경우",
        ],
    },
};

export default chargeTerms;
