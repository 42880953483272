const tier = {
    Bronze: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Bronze.PNG",
    Silver: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Silver.PNG",
    Gold: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Gold.PNG",
    Platinum: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Platinum.PNG",
    Diamond: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Diamond.PNG",
    Master: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Master.PNG",
    Gladiator: "https://despbukkit.s3.ap-northeast-2.amazonaws.com/Gladiator.PNG",
};

export default tier;
