// const baseUri = "https://desp-online.kr:8080"
const baseUri = "http://121.135.44.209:8080"

const backEndUri = {
    rankingPVP: baseUri + "/ranking/pvp",
    rankingLevel: baseUri + "/ranking/level",
    searchPlayer: baseUri + "/search/player/",
    nicknameValidation: baseUri + "/cash/existuser/",
    charge: baseUri + "/cash/charge",
    chargeLog: baseUri + "/cash/chargelog",
    userFace: "https://mc-heads.net/body/"
};

export default backEndUri;
